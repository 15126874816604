<template>
  <div class="langding_container w">
    <!-- 顶部导航的占位 -->
    <QbTopnavPlaceholder></QbTopnavPlaceholder>

    <!--智囊团陪跑计划标题 -->
    <QbTitle :title="title" :complement="complement"></QbTitle>

    <!-- 裂变落地系统封面图 -->
    <QbCover :src="src"></QbCover>

    <!-- 裂变落地系统课程安排 -->
    <LandingSchedule></LandingSchedule>

    <!-- 裂变落地系统内容 -->
    <LandingContent></LandingContent>

    <!-- 报名组件 -->
    <QbApplication></QbApplication>
  </div>
</template>

<script>
import LandingSchedule from './components/landing-schedule.vue'
import LandingContent from './components/landing-content.vue'
export default {
  components: {
    LandingSchedule,
    LandingContent
  },
  data() {
    return {
      title: '《裂变式落地系统》',
      complement: 'Leading the change of business model',
      src: require('@/assets/images/landing/landing_cover.png')
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
}
</style>

<template>
  <div class="landing_schedule_container">
    <div class="landing_schedule w">
      <div class="schedule_title">
        课程安排
      </div>
      <div class="schedule_introduce">
        「裂变落地系统」课程，推动企业管理进入新时代，是思维和经营模式的革命，泉邦集团作为主办方，凝聚实践智慧，以累积数载经营哲学和方法，为经营者传递信心与力量，帮助企业抢占模式变革的制高点，携手TA们一道做管理变革下的逐梦者。
      </div>
      <div class="schedule_content">
        <dl>
          <dt>融资体系<span>学会融资规划，让人才，资金主动找上门来</span></dt>
          <dd>
            <div>消费型股东招募</div>
            <div class="no_mr">企业超级拆分</div>
            <div>资源型股东招募</div>
            <div class="no_mr">企业顶层布局</div>
            <div>投资型股东招募</div>
            <div class="no_mr">企业内部融资</div>
          </dd>
        </dl>
        <dl>
          <dt>招商体系<span>掌握招商部剧，构建销售网络，达到全国裂变</span></dt>
          <dd>
            <div>服务流程体系</div>
            <div class="no_mr">渠道层级分配</div>
            <div>会销成交设计</div>
            <div class="no_mr">城市布局体系</div>
            <div>会务布局体系</div>
            <div class="no_mr">顾客裂变体系</div>
          </dd>
        </dl>
        <dl>
          <dt>会议系统<span>植入会议体系，让企业内部自动自发，良性运</span></dt>
          <dd>
            <div>早会系统</div>
            <div class="no_mr">月会系统</div>
            <div>夕会系统</div>
            <div class="no_mr">例会系统</div>
            <div>启动会系统</div>
            <div class="no_mr">分享会系统</div>
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LandingSchedule'
}
</script>

<style lang="less" scoped>
.landing_schedule {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.schedule_title {
  display: flex;
  justify-content: center;
  font-size: 32px;
  font-weight: 500;
  color: #010343;
  line-height: 44px;
  width: 1373px;
  border-bottom: 3px solid #010343;
  margin-top: 89px;
}

.schedule_introduce {
  display: flex;
  align-items: center;
  width: 1557px;
  height: 123px;
  font-size: 24px;
  font-weight: 400;
  color: #010343;
  line-height: 33px;
  margin-bottom: 15px;
}
.schedule_content {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 113px;
  dl {
    display: flex;
    flex-direction: column;
    margin-right: 44px;
    width: 491px;
  }
  dt {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 491px;
    height: 92px;
    background: #1651dd;
    font-size: 32px;
    font-weight: 500;
    color: #ffffff;
    line-height: 44px;
    padding-top: 13px;
    span {
      height: 27px;
      font-size: 19px;
      font-weight: 300;
      color: #ffffff;
      line-height: 27px;
    }
  }
  dd {
    display: flex;
    flex-wrap: wrap;
    div {
      width: 224px;
      height: 65px;
      background: #f7f9fd;
      font-size: 24px;
      font-weight: 400;
      color: #010343;
      line-height: 65px;
      margin-top: 31px;
      margin-right: 43px;
      text-align: center;
      &.no_mr {
        margin-right: 0;
      }
    }
  }
}
</style>

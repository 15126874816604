<template>
  <div class="landing_content_container">
    <div class="landing_content w">
      <div class="landing_content_left">
        <div class="title">品牌扩张模式究竟应该如何抉择？</div>
        <div class="question">
          随着企业的经营渐入佳境不断扩张，相信越来越多的企业经营者都在选择直营模式还是加盟模式产生纠结。<br />
          就拿直营与加盟模式用处最广的餐饮行业，各类巨头的选择也各不相同：直营、加盟、直营与加盟结合，可见，单是直营模式与加盟模式的组合就值得一家企业反复考量。更何况这些模式背后更为复杂繁琐的体系，也将不断消磨企业经营者的精力。
        </div>
        <div class="process">
          <ul>
            <li>
              <div class="process_number">ONE</div>
              <div class="process_content">
                <h1>商业模式设计</h1>
                我们将为您带来设计模式的核心：把有形资产打包到无形产品中去；让生意更简单，让钱更好赚的模式就是好模式。
              </div>
            </li>
            <li>
              <div class="process_number">TWO</div>
              <div class="process_content">
                <h1>融合社会资源</h1>
                我们将为您带来5种员工入股方案，7种高管入股及激励方案。现场板书案例，教学员做方案的逻辑，算法及步骤。
              </div>
            </li>
            <li>
              <div class="process_number">THREE</div>
              <div class="process_content">
                <h1>招募加盟商设计</h1>
                我们将为您分享加盟的方法，加盟收益的组成部分，招商路演的全套流程，甚至把公司各式会议的流程模版都设计好共享到学员群里
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="landing_content_right">
        <div class="title">裂变落地系统</div>
        <div class="introduction">
          「裂变落地系统」就是让企业原有的产品和项目更好地适应当前的市场环境的全新商业模式和系统。通过转变企业经营者经营思维，改变经营方式，以实现裂变，从而创造更多盈利，最终实现企业的人才裂变，渠道裂变，时间裂变
        </div>
        <div class="ace_lecturer">课程金牌讲师</div>
        <div class="content">
          <div class="lecturer">
            <div class="lecturer_avatar">
              <img src="@/assets/images/landing/mbc.png" alt="" />
            </div>
            <div class="lecturer_name">马煜超</div>
            <div class="lecturer_identity">
              <div>泉邦集团董事长</div>
            </div>
          </div>
          <div class="process">
            <ul>
              <li>
                <div class="process_number">ONE</div>
                <div class="process_title">
                  方案落地
                </div>
              </li>
              <li>
                <div class="process_number">TWO</div>
                <div class="process_title">
                  见效迅速
                </div>
              </li>
              <li>
                <div class="process_number">THREE</div>
                <div class="process_title">
                  提升竞争力
                </div>
              </li>
              <li>
                <div class="process_number">FOUR</div>
                <div class="process_title">
                  良性循环
                </div>
              </li>
              <li>
                赋予别人赚钱的能力是企业家最大的资本。
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LandingContent'
}
</script>

<style lang="less" scoped>
.landing_content {
  display: flex;
  justify-content: center;
  margin-bottom: 109px;
}
.landing_content_left {
  width: 757px;
  height: 939px;
  margin-left: 43px;
  padding: 43px 31px 0px 32px;
  .title {
    font-size: 32px;
    font-weight: 500;
    color: #010343;
  }
  .question {
    width: 695px;
    font-size: 24px;
    font-weight: 400;
    color: #010343;
    line-height: 48px;
    margin-top: 30px;
    margin-bottom: 20px;
  }
  ul {
    display: flex;
    flex-direction: column;
  }
  li {
    display: flex;
    margin-bottom: 39px;
  }
  .process_number {
    width: 77px;
    height: 132px;
    line-height: 132px;
    background: #1651dd;
    margin-right: 11px;
    font-size: 19px;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
  }
  .process_content {
    width: 607px;
    height: 132px;
    background: #f7f9fd;
    padding: 20px 29px 25px 47px;
    font-size: 16px;
    font-weight: 500;
    color: #010343;
    line-height: 25px;
    h1 {
      font-size: 24px;
      font-weight: 500;
      color: #010343;
      line-height: 33px;
    }
  }
}

.landing_content_right {
  width: 757px;
  height: 939px;
  padding: 43px 31px 0px 32px;
  .title {
    font-size: 32px;
    font-weight: 500;
    color: #010343;
    line-height: 44px;
  }
  .introduction {
    width: 695px;
    height: 192px;
    font-size: 24px;
    font-weight: 400;
    color: #010343;
    line-height: 48px;
    margin-top: 30px;
    margin-bottom: 40px;
  }
  .ace_lecturer {
    font-size: 24px;
    font-weight: 500;
    color: #010343;
  }
  .content {
    display: flex;
  }

  .lecturer {
    display: flex;
    flex-direction: column;
    margin-top: 23px;
    margin-right: 13px;
    .lecturer_avatar {
      width: 224px;
      height: 230px;
    }
    .lecturer_name {
      width: 224px;
      height: 45px;
      line-height: 45px;
      background: #1651dd;
      font-size: 24px;
      font-weight: 500;
      color: #ffffff;
      text-align: center;
      margin-top: 15px;
    }
    .lecturer_identity {
      width: 224px;
      height: 205px;
      background: #f7f9fd;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      font-size: 19px;
      font-weight: 400;
      color: #010343;
      line-height: 27px;
    }
  }

  .process {
    li {
      display: flex;
      margin-top: 21px;
      &:nth-child(5) {
        padding: 31px 29px 40px 15px;
        width: 457px;
        height: 145px;
        background: #1651dd;
        font-size: 24px;
        font-weight: 600;
        color: #ffffff;
        line-height: 37px;
        text-align: center;
      }
    }
    .process_number {
      width: 67px;
      height: 67px;
      background: #1651dd;
      font-size: 19px;
      font-weight: 500;
      color: #ffffff;
      line-height: 67px;
      text-align: center;
    }
    .process_title {
      width: 381px;
      height: 67px;
      line-height: 67px;
      text-align: center;
      background: #f7f9fd;
      font-size: 24px;
      font-weight: 500;
      color: #010343;
    }
  }
}
</style>
